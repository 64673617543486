export enum MessageType {
  SystemMessage = "systemMessage",
  UserMessage = "userMessage",
}

export enum ChatType {
  orderChat = "orderChat",
  supportChat = "supportChat",
  exchangeChat = "exchangeChat",
}
